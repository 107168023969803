<template>
  <div class="ErrorModal">
    <div class="modal fade">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" style="border-radius: 10px">
          <div class="modal-header bg-dark-blue text-white">
            <h5 class="modal-title">ไม่สามารถเข้าสู่ระบบได้</h5>
            <button type="button" class="text-white close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              ไม่สามารถเข้าสู่ระบบได้ กรุณาตรวจสอบยูสเซอร์เนมและรหัสผ่าน
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-dark-blue" data-dismiss="modal">ตกลง</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
.bg-dark-blue {
  background: rgba(31, 49, 84, 1);
}
.btn-dark-blue {
  background: rgba(31, 49, 84, 1);
  color: white;
}
</style>
