<template>
  <div class="Welcome px-2">
    <div class="container p-1">
      <!-- <app-contentbox /> -->
      <app-loginbox />
    </div>
  </div>
</template>

<script>
// import Contentbox from '@/pages/welcomePage/content/Contentbox.vue'
import Loginbox from '@/pages/welcomePage/content/Login_single.vue'
export default {
  components: {
    'app-loginbox': Loginbox
    // 'app-contentbox': Contentbox
  }
}
</script>

<style>
/************* button-select-category ***********/
.button-select-category .dropdown-menu .dropdown-item {
  padding: 5px 10px;
  border-top: 1px solid #d7eff3;
}
.button-select-category .dropdown-menu .dropdown-item:first-child {
  border-top: 1px solid white;
}
.button-select-category .dropdown-toggle::before,
.button-select-category .dropdown-toggle::after {
  /* display: none;  */
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.button-select-category {
  /* display: none; */

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;

  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 15;
  text-align: center;
}
.button-select-category i.fa-times {
  display: none;
  font-style: normal;
}
.button-select-category a {
  color: #970215 !important;
  display: block;
  cursor: pointer;
  font-size: 14px;
}
.button-select-category a.text-dark {
  color: #fff !important;
  background: #970215 !important;
  display: block;
  cursor: pointer;
  font-size: 14px;
}
.button-select-category a.text-dark:active {
  color: #fff !important;
  /*background: #b90504 !important;*/
  background: #b90504 !important;
  display: block;
  cursor: pointer;
  font-size: 14px;
}
.button-select-category a:active {
  color: #fff !important;
  /* background: #970215 !important; */
  background: #b90504 !important;
  display: block;
  cursor: pointer;
  font-size: 14px;
}
.button-select-category.show i.fa-times {
  display: block;
}
.button-select-category.show i.fa-search {
  display: none;
}
.button-select-category button {
  width: 47px;
  height: 47px;
  line-height: 24px;
  padding: 9px 10px;
  text-align: center;
  /* color: #fff; */
  color: #970215;
  border-radius: 47px;
  /* background-color: #970215; */
  background-color: #fff;
  border: 3px solid #b90504;
  -webkit-box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
}
.button-select-category button i {
  /* color: #fff; */
  color: #970215;
  font-size: 24px;
  vertical-align: middle;
  text-align: center;
}
/************* end button-select-category ***********/
</style>
