<template>
  <div class="login">
    <div class="wrapper">
        <form ref="loginform"  class="p-3 mt-3" @submit.prevent="onSubmit">
            <div class="form-field d-flex align-items-center">
                <span class="far fa-user"></span>
                <input type="text"
                  name="username"
                  id="username"
                  v-model="username"
                  required="required"
                  v-bind:placeholder="$t('username')">
            </div>
            <div class="form-field d-flex align-items-center">
                <span class="fas fa-key"></span>
                <input
                  type="password"
                  name="password"
                  v-model="password"
                  id="password"
                  v-bind:placeholder="$t('password')"
                  required="required">
            </div>
            <button class="btn mt-3" type="submit">
              {{ $t('signin') }}
            </button>
        </form>
        <div class="text-center fs-6">
          <router-link to="/forgetpassword" class="form-check-label"><i class="fas fa-unlock"></i> {{ $t('forgot') }}</router-link>
        </div>
    </div>
    <app-error-modal />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import Sublogin from '@/pages/welcomePage/content/Sublogin.vue'
import ErrorModal from '@/components/modals/ErrorLogin.vue'

export default {
  components: {
    // 'app-sublogin': Sublogin,
    'app-error-modal': ErrorModal
  },
  data: function () {
    return {
      language: localStorage.getItem('locale') ?? 'th',
      username: null,
      password: null
    }
  },
  computed: {
    iconsFlags: function () {
      return this.language === 'th' ? 'flag-icon flag-icon-th' : 'flag-icon flag-icon-gb'
    },
    ...mapState({ activeError: state => state.errorModule.activeError })
  },
  methods: {
    ...mapActions(['loginAction', 'requestUsername', 'setLoading', 'openErrorPopup', 'reqConfig']),
    async onSubmit () {
      if (!this.username && !this.password) {
        return false
      } else {
        this.setLoading(true)
        const isSucces = await this.loginAction({
          username: this.username,
          password: this.password
        })
        if (isSucces) {
          this.requestUsername()
          this.setLoading(false)
          this.reqConfig(process.env.VUE_APP_AG_ID)
          this.$router.replace('member/main')
        } else {
          this.setLoading(false)
          this.$refs.loginform.reset()
          return false
        }
      }
    },
    selectLangauge (lang) {
      this.language = lang
      this.$i18n.locale = lang?.toLowerCase()
      localStorage.setItem('locale', lang?.toLowerCase())
    }
  }
}
</script>

<style>

#app {
    background: transparent ;
}

.wrapper {
    max-width: 350px;
    min-height: 350px;
    margin: 80px auto;
    padding: 40px 30px 30px 30px;
    background-color: #ecf0f3;
    border-radius: 15px;
    box-shadow: 2px 2px 20px #cbced1, -2px -2px 2px #fff;
}

.logo {
    width: 80px;
    margin: auto;
}

.logo img {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 3px #5f5f5f,
        0px 0px 0px 5px #ecf0f3,
        8px 8px 15px #a7aaa7,
        -8px -8px 15px #fff;
}

.wrapper .name {
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 1.3px;
    padding-left: 10px;
    color: #555;
}

.wrapper .form-field input {
    width: 100%;
    display: block;
    border: none;
    outline: none;
    background: none;
    font-size: 1.2rem;
    color: #666;
    padding: 10px 15px 10px 10px;
    /* border: 1px solid red; */
}

.wrapper .form-field {
    padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff;
}

.wrapper .form-field .fas {
    color: #555;
}

.wrapper .btn {
    box-shadow: none;
    width: 100%;
    height: 40px;
    background-color: #03A9F4;
    color: #fff;
    border-radius: 25px;
    box-shadow: 3px 3px 3px #b1b1b1,
        -3px -3px 3px #fff;
    letter-spacing: 1.3px;
}

.wrapper .btn:hover {
    background-color: #039BE5;
}

.wrapper a {
    text-decoration: none;
    font-size: 0.8rem;
    color: #03A9F4;
}

.wrapper a:hover {
    color: #039BE5;
}

@media(max-width: 380px) {
    .wrapper {
        margin: 30px 20px;
        padding: 40px 15px 15px 15px;
    }
}
</style>
